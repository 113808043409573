export type Services = {
  lechenie: Service
  protezirovanie: Service
  hirurgiya: Service
  profilaktika: Service
}

export type Price = {
  readonly title: string
  readonly price: string
}

export type Master = {
  readonly fio: string
  readonly image?: string
  readonly skill: string
  readonly stage: string
  readonly since: string
}

export type Education = {
  name: string
  year: string
  type: string
}

export type Service = {
  readonly title: string
  readonly desc: string
  readonly master: Master
  readonly education: {
    main: Education[]
    secondary: Education[]
  }
  readonly prices: Price[]
}

export const services: Services = {
  lechenie: {
    title: 'Терапевтические услуги',
    desc: 'Терапевтическое лечение зубов – это метод стоматологического лечения, заключающийся в удалении поврежденных кариозным процессом некротизированных  тканей зуба, первичном, при осложненном течении кариеса, и повторном эндодонтическом лечении  корневых каналов с восстановлением композитными светоотверждаемыми материалами или керамическими вкладками.',
    master: {
      fio: 'Борчанинов Максим Олегович',
      image: 'assets/images/team/maks-4.jpg',
      skill: 'Стоматолог-хирург-имплантолог',
      stage: '14',
      since: 'Южно-Уральский государственный медицинский университет',
    },
    education: {
      main: [
        {
          name: 'Курганский базовый медицинский колледж (зубоврачебное дело)',
          year: '2008',
          type: 'Образование',
        },
        {
          name: 'Южно-Уральский государственный медицинский университет (стоматология)',
          year: '2013',
          type: 'Базовое образование',
        },
        {
          name: 'Южно-Уральский государственный медицинский университет (стоматология ортопедическая)',
          year: '2013',
          type: 'Базовое образование',
        },
      ],
      secondary: [
        {
          name: '«Эстетика улыбки», г Екатеринбург',
          year: '2010',
          type: '',
        },
        {
          name: '«Эффективная профилактика в стоматологии: новые технологии в рамках обучающих программ:VocoGmbN (Германия)», г. Екатеринбург. Семинар Доктора Мартона Якубовича «Имплантация и костная пластика», Германия',
          year: '2010',
          type: '',
        },
        {
          name: '«Протезирование на имплантатах», г. Челябинск',
          year: '2015',
          type: '',
        },
        {
          name: '«Планирование ортопедического лечения», курс Дажаева Г.П., г. Челябинск',
          year: '2015',
          type: '',
        },
        {
          name: '«Теоретический и практический курс «мастерство протезирования»»',
          year: '2016',
          type: '',
        },
        {
          name: '«Мастерство протезирования», г. Санкт-Петербург',
          year: '2016',
          type: '',
        },
        {
          name: '«Хирургические аспекты использования имплантатов Straumann», г. Москва',
          year: '2017',
          type: '',
        },
        {
          name: '«Современные аспекты несъемного протезирования. Микропротезирование», г. Челябинск',
          year: '2017',
          type: '',
        },
        {
          name: '«Имплантационная система Straumann: базовый ортопедический курс», г. Москва',
          year: '2017',
          type: '',
        },
        {
          name: '«Прогнозируемая направленная регенерация костной ткани в дентальной имплантологии с использованием титановой сетки. Протоколы минимизации осложнений», г. Челябинск',
          year: '2018',
          type: '',
        },
        {
          name: '«Комплексная консультация врача – стоматолога», г. Челябинск',
          year: '2018',
          type: '',
        },
      ],
    },
    // master: {
    //   fio: 'Щепетова Марина Андреевна',
    //   // image: 'assets/images/team/none.jpg',
    //   skill: 'Стоматолог-терапевт',
    //   stage: '8',
    //   since: 'Южно-Уральский государственный медицинский университет',
    // },
    // education: {
    //   main: [
    //     {
    //       name: 'Южно-Уральский государственный медицинский университет (стоматология)',
    //       year: '2014',
    //       type: 'Базовое образование',
    //     },
    //     {
    //       name: 'Южно-Уральский государственный едицинский университет (стоматология общей практики)',
    //       year: '2015',
    //       type: 'Интернатура',
    //     },
    //     {
    //       name: 'Южно-Уральский государственный медицинский университет (стоматология терапевтическая)',
    //       year: '2015',
    //       type: 'Циклы переподготовки',
    //     },
    //   ],
    //   secondary: [
    //     {
    //       name: '«Практические нюансы каждодневной эндодонтии», М. Соломонов, г. Москва',
    //       year: '2015',
    //       type: '',
    //     },
    //     {
    //       name: '«Функциональная реабилитация зубов после эндодонтического лечения», Подрезов А. М., г. Челябинск',
    //       year: '2015',
    //       type: '',
    //     },
    //     {
    //       name: '«Эффективное применение ультразвука в эндодонтии», EMS , г. Челябинск',
    //       year: '2015',
    //       type: '',
    //     },
    //     {
    //       name: '«Никель – титановые инструменты пятого поколения. Прикладная эндодонтия. Искусство в деталях», Шляхтова И. А. г. Челябинск',
    //       year: '2015',
    //       type: '',
    //     },
    //     {
    //       name: '«Восстановление анатомической формы передней и боковой группы зубов в эстетических реставрациях», А. В. Ветчинкин, г. Санкт-Петербург',
    //       year: '2016',
    //       type: '',
    //     },
    //     {
    //       name: '«Обзор современных методов инструментации и обтурации корневых каналов», М. Соломонов, г. Москва',
    //       year: '2016',
    //       type: '',
    //     },
    //     {
    //       name: '«Всё гениальное просто. Простой взгляд на эстетику стоматологии. Реставрация зубов фронтальной группы», Кондратьева В.С., г. Челябинск',
    //       year: '2016',
    //       type: '',
    //     },
    //     {
    //       name: '«Реставрация зубов с использованием современных материалов», учебный центр «Витал ЕВВ», г. Екатеринбург',
    //       year: '2016',
    //       type: '',
    //     },
    //     {
    //       name: 'Научно-практическая конференция «Эстетическая стоматология», Учебный центр Global Dent',
    //       year: '2016',
    //       type: '',
    //     },
    //     {
    //       name: '«Прямая реставрация жевательной группы зубов», Р. Алиев, г. Москва',
    //       year: '2017',
    //       type: '',
    //     },
    //     {
    //       name: '«Перелечивание. Клинические решения и техники», М. Соломонов, г. Москва',
    //       year: '2018',
    //       type: '',
    //     },
    //     {
    //       name: '«Прямая композитная реставрация передних зубов». Д. В. Крутиков г. Москва',
    //       year: '2019',
    //       type: '',
    //     },
    //     {
    //       name: '«Часто задаваемые вопросы по эндодонтии. Часть 1. Эффективная дезинфекция и лечение апикального периодонтита. Алгоритм механической и медикаментозной обработки». Илья Мер г. Москва',
    //       year: '2020',
    //       type: '',
    //     },
    //     {
    //       name: '«Увеличение без границ» Т. А. Бойцова г. Екатеринбург',
    //       year: '2021',
    //       type: '',
    //     },
    //     {
    //       name: '«Direct Posterior, Direct Posterior II» Роман Карпенко г. Екатеринбург',
    //       year: '2022',
    //       type: '',
    //     },
    //   ],
    // },
    prices: [
      {
        title: 'Прием(осмотр,консультация)врача-стоматолога',
        price: 'бесплатно',
      },
      {
        title: 'Кариес дентина',
        price: '6200',
      },
      {
        title: 'Кариес депульпированного зуба',
        price: '6700',
      },
      {
        title: 'Пульпит резца',
        price: '11500',
      },
      {
        title: 'Пульпит премоляра',
        price: '13500',
      },
      {
        title: 'Периодонтит резца',
        price: '13000',
      },
      {
        title: 'Пульпит моляра',
        price: '16500',
      },
      {
        title: 'Периодонтит премоляра',
        price: '15000',
      },
      {
        title: 'Периодонтит моляра',
        price: '18000',
      },
      {
        title: 'Реставрация зуба фронтального отдела',
        price: '7500',
      },

      {
        title: 'Установка стекловолоконного штифта',
        price: '3500',
      },
      {
        title: 'Удаление штифта',
        price: '2000',
      },
      {
        title: 'Избирательное пришлифовывание',
        price: '500',
      },
      {
        title: 'Диагностическое препарирование',
        price: '3000',
      },
      {
        title: 'Керамическая вкладка Е-мах',
        price: '21000',
      },
      {
        title: 'Распломбировка одного корневого канала',
        price: '550',
      },
      {
        title: 'Справка о санации',
        price: '350',
      },
    ],
  },
  hirurgiya: {
    title: 'Хирургические услуги',
    desc: 'Хирургическое лечение-это метод стоматологического лечения, заключающийся в удалении хронических, острых процессов, если невозможно решить проблему,  используя терапевтические методы, с последующим восстановлением отсутствующих зубов путем имплантации, костной пластики и пластики мягких тканей.',
    master: {
      fio: 'Борчанинов Максим Олегович',
      image: 'assets/images/team/maks-4.jpg',
      skill: 'Стоматолог-хирург-имплантолог',
      stage: '14',
      since: 'Южно-Уральский государственный медицинский университет',
    },
    education: {
      main: [
        {
          name: 'Курганский базовый медицинский колледж (зубоврачебное дело)',
          year: '2008',
          type: 'Образование',
        },
        {
          name: 'Южно-Уральский государственный медицинский университет (стоматология)',
          year: '2013',
          type: 'Базовое образование',
        },
        {
          name: 'Южно-Уральский государственный медицинский университет (стоматология ортопедическая)',
          year: '2013',
          type: 'Базовое образование',
        },
      ],
      secondary: [
        {
          name: '«Эстетика улыбки», г Екатеринбург',
          year: '2010',
          type: '',
        },
        {
          name: '«Эффективная профилактика в стоматологии: новые технологии в рамках обучающих программ:VocoGmbN (Германия)», г. Екатеринбург. Семинар Доктора Мартона Якубовича «Имплантация и костная пластика», Германия',
          year: '2010',
          type: '',
        },
        {
          name: '«Протезирование на имплантатах», г. Челябинск',
          year: '2015',
          type: '',
        },
        {
          name: '«Планирование ортопедического лечения», курс Дажаева Г.П., г. Челябинск',
          year: '2015',
          type: '',
        },
        {
          name: '«Теоретический и практический курс «мастерство протезирования»»',
          year: '2016',
          type: '',
        },
        {
          name: '«Мастерство протезирования», г. Санкт-Петербург',
          year: '2016',
          type: '',
        },
        {
          name: '«Хирургические аспекты использования имплантатов Straumann», г. Москва',
          year: '2017',
          type: '',
        },
        {
          name: '«Современные аспекты несъемного протезирования. Микропротезирование», г. Челябинск',
          year: '2017',
          type: '',
        },
        {
          name: '«Имплантационная система Straumann: базовый ортопедический курс», г. Москва',
          year: '2017',
          type: '',
        },
        {
          name: '«Прогнозируемая направленная регенерация костной ткани в дентальной имплантологии с использованием титановой сетки. Протоколы минимизации осложнений», г. Челябинск',
          year: '2018',
          type: '',
        },
        {
          name: '«Комплексная консультация врача – стоматолога», г. Челябинск',
          year: '2018',
          type: '',
        },
      ],
    },
    prices: [
      {
        title: 'Прием(осмотр,консультация)врача-стоматолога',
        price: 'бесплатно',
      },
      {
        title: 'Удаление зуба простое',
        price: '2700',
      },
      {
        title: 'Удаление зуба сложное',
        price: '4500',
      },
      {
        title: 'Удаление ретинированного зуба',
        price: '7500',
      },
      {
        title: 'Установка импланта IMPLANTIUM',
        price: '18000',
      },
      {
        title: 'Установка импланта STRAUMANN',
        price: '36000',
      },
      {
        title: 'Открытый синус- лифтинг',
        price: '32000',
      },
      {
        title: 'Закрытый синус-лифтинг',
        price: '12000',
      },
      {
        title: 'Удаление импланта',
        price: '8000',
      },
      {
        title: 'Гингивэктомия 1 зуб',
        price: '2000',
      },
      {
        title:
          'Повязка,медикаментозная обработка раны после оперативных вмешательств',
        price: '1000',
      },
      {
        title:
          'Коррекция объема и формы альвеолярного отростка при костных экзостозах',
        price: '1500',
      },
      {
        title: 'Вскрытие подслизистого или поднадкостничного очага воспаления',
        price: '2000',
      },
      {
        title: 'Удаление корня зуба',
        price: '1000',
      },
    ],
  },
  protezirovanie: {
    title: 'Ортопедические услуги',
    desc: 'Ортопедическое лечение – это метод стоматологического лечения, направленный на восстановление жевательной функции с использованием имплантов, своих зубов или съемных конструкций,  восполнение эстетических потребностей, а так же на профилактику разрушения депульпированных зубов, требующих изготовления керамических конструкций.',
    master: {
      fio: 'Борчанинов Максим Олегович',
      image: 'assets/images/team/maks-4-2.jpg',
      skill: 'Стоматолог-ортопед',
      stage: '14',
      since: 'Южно-Уральский государственный медицинский университет',
    },
    education: {
      main: [
        {
          name: 'Курганский базовый медицинский колледж (зубоврачебное дело)',
          year: '2008',
          type: 'Образование',
        },
        {
          name: 'Южно-Уральский государственный медицинский университет (стоматология)',
          year: '2013',
          type: 'Базовое образование',
        },
        {
          name: 'Южно-Уральский государственный медицинский университет (стоматология ортопедическая)',
          year: '2013',
          type: 'Базовое образование',
        },
      ],
      secondary: [
        {
          name: '«Эстетика улыбки», г. Екатеринбург',
          year: '2010',
          type: '',
        },
        {
          name: '«Эффективная профилактика в стоматологии: новые технологии в рамках обучающих программ:VocoGmbN (Германия)», г. Екатеринбург',
          year: '2010',
          type: '',
        },
        {
          name: '«Протезирование на имплантатах», г. Челябинск',
          year: '2015',
          type: '',
        },
        {
          name: '«Философия оттиска. Каждодневная рутина. Взгляд на оттиск с необычной стороны», г. Челябинск',
          year: '2015',
          type: '',
        },
        {
          name: '«Планирование ортопедического лечения», курс Дажаева Г.П., г. Челябинск',
          year: '2015',
          type: '',
        },
        {
          name: '«Теоретический и практический курс «мастерство протезирования»»',
          year: '2016',
          type: '',
        },
        {
          name: '«Фиксированное протезирование. От препарирования до снятия оттисков», г. Челябинск',
          year: '2016',
          type: '',
        },
        {
          name: '«Мастерство протезирования», г. Санкт-Петербург',
          year: '2016',
          type: '',
        },
        {
          name: '«Современные аспекты несъемного протезирования. Микропротезирование», г. Челябинск',
          year: '2017',
          type: '',
        },
        {
          name: '«Имплантационная система Straumann: базовый ортопедический курс», г. Москва',
          year: '2017',
          type: '',
        },
        {
          name: '«Адгезивные керамические реставрации», г. Челябинск',
          year: '2017',
          type: '',
        },
        {
          name: '«Комплексная консультация врача – стоматолога», г. Челябинск',
          year: '2018',
          type: '',
        },
      ],
    },
    prices: [
      {
        title: 'Прием(осмотр,консультация)врача-стоматолога',
        price: 'бесплатно',
      },
      {
        title: 'Керамический винир на зуб',
        price: '19000',
      },
      {
        title: 'Керамическая вкладка E-max',
        price: '18000',
      },
      {
        title: 'Коронка металлокерамическая на зуб',
        price: '11000',
      },
      {
        title: 'Коронка безметалловая на зуб',
        price: '17500',
      },
      {
        title: 'Коронка металлокерамическая на имплант IMPLANTIUM',
        price: '18000',
      },
      {
        title: 'Коронка безметалловая на имплант IMPLANTIUM',
        price: '22000',
      },
      {
        title: 'Коронка металлокерамическая на импланте STRAUMANN',
        price: '30000',
      },
      {
        title: 'Коронка безметалловая на имплант STRAUMANN',
        price: '34000',
      },
      {
        title: 'Абатмент из диоксида циркония IMPLANTIUM',
        price: '8000',
      },
      {
        title: 'Абатмент из диоксида циркония STRAUMANN',
        price: '12000',
      },
      {
        title: 'Коронка временная  на имплант IMPLANTIUM',
        price: '7500',
      },
      {
        title: 'Коронка временная на импланте STRAUMANN',
        price: '9500',
      },
      {
        title: 'Съемный протез на балочной опоре IMLANTIUM',
        price: '165000',
      },
      {
        title: 'Съемный протез на балочной опоре SRAUMANN',
        price: '230000',
      },
      {
        title: 'Шаровидный абатмент',
        price: '17000',
      },
      {
        title: 'Бюгельный протез',
        price: '40000',
      },
      {
        title: 'Съемный пластиночный протез',
        price: '30000',
      },
      {
        title: 'Съемный косметический протез',
        price: '10000',
      },
      {
        title: 'Приварка 1 зуба,кламмера в съемном протезе',
        price: '2500',
      },
      {
        title: 'Починка протеза',
        price: '3000',
      },
      {
        title: 'Коррекция протеза',
        price: '500',
      },
      {
        title: 'Перебазировка протеза',
        price: '4000',
      },
      {
        title: 'Снятие коронок за единицу',
        price: '1000',
      },
      {
        title:
          'Повторная фиксация несъемных ортопедических конструкций за единицу',
        price: '1000',
      },
      {
        title: 'Восстановление зуба временной фрезерованнной коронкой',
        price: '5000',
      },
      {
        title: 'Исследование диагностических моделей челюсти',
        price: '4000',
      },
      {
        title: 'Изготовление индивидуальной каппы (1 челюсть)',
        price: '6000',
      },
    ],
  },
  profilaktika: {
    title: 'Профилактические услуги',
    desc: 'Профилактика-это направление стоматологии, позволяющее избежать  терапевтического, хирургического и ортопедического лечения  или свести к минимуму возможность развития ситуация, приводящих к развитию кариозных процессов зубов, воспалительных процессов десен и связочного аппарата зуба, а так же развития осложнения имплантации в виде периимплантита со всеми вытекающими последствиями.',
    master: {
      fio: 'Фуст Кристина Андреевна',
      image: 'assets/images/team/kris-4.jpg',
      skill: 'Стоматолог-гигиенист',
      stage: '10',
      since: 'Миасский медицинский колледж (гигиенист стоматологический)',
    },
    education: {
      main: [
        {
          name: 'Челябинский медицинский колледж (сестринское дело)',
          year: '2012',
          type: 'Образование',
        },
        {
          name: 'Миасский медицинский колледж (гигиенист стоматологический)',
          year: '2020',
          type: 'Базовое образование',
        },
      ],
      secondary: [
        {
          name: 'Курс Н.В. Озеранской «Основы работы гигиениста стоматологического». г. Челябинск',
          year: '2019',
          type: '',
        },
        {
          name: 'Курс Волчковой Л.А.«Современные методы нехирургического лечения заболеваний пародонта» г.Екатеринбург',
          year: '2021',
          type: '',
        },
        {
          name: 'Курс Овечкиной М.В. «Применение аутологичной плазмы в практике врача-стоматолога» г.Казань',
          year: '2022',
          type: '',
        },
      ],
    },
    prices: [
      {
        title: 'Прием(осмотр,консультация)врача-стоматолога',
        price: 'бесплатно',
      },
      {
        title: 'Профессиональная гигиена полости рта и зубов (первичная)',
        price: '4000',
      },
      {
        title: 'Профессиональная гигиена полости рта(повторная)',
        price: '3000',
      },
      {
        title: 'Профессиональная гигиена полости рта Профи',
        price: '5500',
      },
      {
        title: 'Профессиональное ламповое отбеливание Amaizing',
        price: '12000',
      },
      {
        title: 'Плазмотерапия 1 пробирка',
        price: '4500',
      },
      {
        title: 'Наложение повязок 1 сегмент',
        price: '500',
      },
    ],
  },
}
